export function addTargetsToLinks() {
    const node = document.getElementById("links-target")
    if (node) {
        const target = node.dataset.target
        if (target) {
            if (node.dataset.hrefs && node.dataset.hrefs.length) {
                for (const href of node.dataset.hrefs.split(/\s+/)) {
                    for (const link of document.querySelectorAll(`a[href='${href}']`)) {
                        link.setAttribute("target", target)
                    }
                }
            }
        }
    }
}
