import { detailsHandler } from './detailbox'
import { search_icon_click, search_input_keyup, aldryn_search_submit } from './search'
import { addTargetsToLinks } from './link-target'

addTargetsToLinks()

window.addEventListener('DOMContentLoaded', () => {
    document.getElementById('search-icon').addEventListener('click', search_icon_click)
    document.getElementById('search-input').addEventListener('keyup', search_input_keyup)
    document.getElementById('aldryn-search').addEventListener('submit', aldryn_search_submit)
    detailsHandler()

    // Open first according collapse
    for (const collapse of document.querySelectorAll('.collapse-box')) {
        const trigger = collapse.querySelector('div[id^=trigger]')
        if (trigger.getAttribute('aria-expanded') === 'false') {
            trigger.click()
        }
    }

    /* Menu button action */
    document.querySelector('.menu-btn').addEventListener('click', () => {
        document.querySelector('header').classList.toggle('show-nav')
    })

    /* When is display block third level menu */
    const menu = document.querySelector('.main-menu')
    if (menu.querySelector('.active.undermenu__item .menu-underwrapper')) {
        menu.classList.add('three-level-menu')
    }
})
