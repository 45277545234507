const SEARCH_TEXT_MIN = 2


function search_input_hide() {
    // hide search input
    document.getElementById('search-wrapper').style.display = 'none'
    document.getElementById('logo-light').style.display = 'block'
    document.getElementById('logo-dark').style.display = 'none'
    document.getElementById('search-icon').src = window.searchUrlLight
}


function search_input_show_and_focus() {
    document.getElementById('search-wrapper').style.display = 'block'
    document.getElementById('logo-light').style.display = 'none'
    document.getElementById('logo-dark').style.display = 'block'
    // Value 'searchUrlDark' is defined as inline on the page.
    document.getElementById('search-icon').src = window.searchUrlDark
    document.getElementById('search-input').focus()
}


export function search_icon_click() {
    // Click handler for search icon.
    if (document.getElementById('search-wrapper').style.display === 'block') {
        if (document.getElementById('search-input').value.length >= SEARCH_TEXT_MIN) {
            // submit form
            document.getElementById('aldryn-search').submit()
        } else {
            // hide search input
            search_input_hide()
        }
    } else {
        // display search input
        search_input_show_and_focus()
    }
}


export function search_input_keyup(event) {
    if (event.key === "Escape") {
        search_input_hide()
        return
    }
    // Key-up handler fot search input.
    if (event.target.value.length > 1) {
        // activate serach
        document.getElementById('search-icon').src = window.searchUrlLight
    } else {
        // deactivate serach
        document.getElementById('search-icon').src = window.searchUrlDark
    }
}


export function aldryn_search_submit(event) {
    // Do not submit search form when text in search input is lower than minimum.
    if (document.getElementById('search-input').value.length < SEARCH_TEXT_MIN) {
        event.preventDefault()
    }
}
