/* global gettext */

function openDetails(e) {
    e.preventDefault()
    const link = e.target
    const matrixBlock = link.parentNode.parentNode
    matrixBlock.querySelector('.matrix-block__popup').classList.add('detail')
}

function closeDetails() {
    document.querySelector('.matrix-block__popup.detail').classList.remove('detail')
}

function onClickListener(links, func, title) {
    if (links) {
        for (const link of links) {
            link.style.cursor = "pointer"
            if (title) {
                link.title = title
            }
            link.addEventListener('click', func)
        }
    }
}

export function detailsHandler() {
    let div, link, text
    for (const box of document.querySelectorAll('.matrix-block')) {
        if (!box.querySelectorAll('.matrix-block__btn a').length) {
            div = document.createElement("DIV")
            div.classList.add("matrix-block__btn")
            link = document.createElement("A")
            link.style.cursor = "pointer"
            link.title = gettext("See more.")
            text = document.createTextNode(gettext("More"))
            link.appendChild(text)
            div.appendChild(link)
            box.appendChild(div)
        }
    }

    const omniaLinks = document.querySelectorAll('.matrix-block__btn a')
    onClickListener(omniaLinks, openDetails)

    const closePopupLinks = document.querySelectorAll('.popup-block .icon-cross, .matrix-block__popup')
    onClickListener(closePopupLinks, closeDetails, gettext("Close detail."))
}